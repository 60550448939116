import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { maxInnerWrapperWidth, colors, media } from "../../../constants/theme";
import { paths } from "../../../constants/paths";
import { TrackClick } from "../../../utils/analytics";
import { JobListingsAdMobile } from "../ads/job-listings-mobile-ad";
import { PARTNER_PATHS } from "../../../constants/partner-paths";

const sharedStyles = () => css`
  position: relative;
  padding: 12px 16px;
  transition: 0.2s ease-in-out;
  margin-bottom: 12px;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  background-color: ${colors.primaryRed};
  color: ${colors.white};

  &:hover {
    cursor: pointer;
  }

  ${media.desktop`
    width: calc(100% / 3);
    margin: 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  `}
`;

const Wrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
`;

const Content = styled.div`
  max-width: ${maxInnerWrapperWidth};
  margin: 140px auto 120px;
  text-align: center;
`;
const Heading = styled.h1`
  text-transform: capitalize;
  font-size: 30px;
  letter-spacing: -0.05em;
  margin-bottom: 4px;

  ${media.desktop`
    font-size: 40px;
  `}
`;
const SubHeading = styled.p`
  margin: 0;
  color: ${colors.lighterText};
  font-weight: 300;
`;

const ActionBlocks = styled.div`
  display: flex;
  max-width: 1020px;
  margin: 40px auto 0;
  justify-content: center;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
`;

const NewsletterBlock = styled.div`
  ${sharedStyles}
`;
const ActionBlock = styled(Link)`
  ${sharedStyles}
`;

const OutboundActionBlock = styled.a`
  ${sharedStyles}
`;

const ActionBlockCopy = styled.p`
  margin: 0;
  font-size: 16px;
  letter-spacing: -0em;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 16px;
    margin-left: 4px;
  }
`;

const NewBadge = styled.div`
  background-color: ${colors.linkBlue};
  color: white;
  position: absolute;
  padding: 4px 12px;
  border-radius: 12px;
  top: -11px;
  right: 8px;
  font-size: 12px;
`;

export const SmallHero = ({ category, noRemote, subtitle, title }) => {
  const setHeading = () => {
    if (category === "customerservice") {
      return "customer service";
    }
    if (category === "devopsandsysadmin") {
      return "devops and sysadmin";
    }
    if (category === "seo") {
      return "SEO";
    }
    if (category === "manager") {
      return "manager/exec";
    }
    if (category === "hr") {
      return "HR";
    }
    if (category === "asp net") {
      return "ASP.net";
    }
    if (category === "aws") {
      return "AWS";
    }
    if (category === "node") {
      return "Node JS";
    }
    if (category === "react") {
      return "React Developer";
    }
    if (category === "java") {
      return "Java Developer";
    }
    if (category === "php") {
      return "PHP";
    }
    if (category === "ios") {
      return "iOS Developer";
    }

    return category;
  };

  return (
    <Wrapper>
      <Content>
        <Heading>
          {title ? (
            title
          ) : (
            <>
              {noRemote ? "" : "Remote"} {setHeading()} Jobs{" "}
            </>
          )}
        </Heading>
        <SubHeading>
          {subtitle
            ? subtitle
            : `Fully and partially remote ${setHeading()} jobs from the greatest remote working companies`}
        </SubHeading>

        <ActionBlocks>
          <OutboundActionBlock
            href={PARTNER_PATHS.WORKSTER}
            target="_blank"
            rel="noopener"
            onClick={() => {
              TrackClick({
                category: "Workster",
                action: "Workster - Job Listings - Hero",
                label: "Workster - Job Listings",
              });
            }}
          >
            <NewBadge>US Residents</NewBadge>
            <div>
              <ActionBlockCopy>
                Discover Workster
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path d="M6.22 8.72a.75.75 0 0 0 1.06 1.06l5.22-5.22v1.69a.75.75 0 0 0 1.5 0v-3.5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0 0 1.5h1.69L6.22 8.72Z" />
                  <path d="M3.5 6.75c0-.69.56-1.25 1.25-1.25H7A.75.75 0 0 0 7 4H4.75A2.75 2.75 0 0 0 2 6.75v4.5A2.75 2.75 0 0 0 4.75 14h4.5A2.75 2.75 0 0 0 12 11.25V9a.75.75 0 0 0-1.5 0v2.25c0 .69-.56 1.25-1.25 1.25h-4.5c-.69 0-1.25-.56-1.25-1.25v-4.5Z" />
                </svg>
              </ActionBlockCopy>
            </div>
          </OutboundActionBlock>

          <OutboundActionBlock
            href={PARTNER_PATHS.HUMAN}
            target="_blank"
            rel="noopener"
            onClick={() => {
              TrackClick({
                category: "Human",
                action: "Human - Job Listings - Hero",
                label: "Human - Job Listings",
              });
            }}
          >
            <NewBadge>Professional Profiles</NewBadge>
            <div>
              <ActionBlockCopy>
                Discover Human
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path d="M6.22 8.72a.75.75 0 0 0 1.06 1.06l5.22-5.22v1.69a.75.75 0 0 0 1.5 0v-3.5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0 0 1.5h1.69L6.22 8.72Z" />
                  <path d="M3.5 6.75c0-.69.56-1.25 1.25-1.25H7A.75.75 0 0 0 7 4H4.75A2.75 2.75 0 0 0 2 6.75v4.5A2.75 2.75 0 0 0 4.75 14h4.5A2.75 2.75 0 0 0 12 11.25V9a.75.75 0 0 0-1.5 0v2.25c0 .69-.56 1.25-1.25 1.25h-4.5c-.69 0-1.25-.56-1.25-1.25v-4.5Z" />
                </svg>
              </ActionBlockCopy>
            </div>
          </OutboundActionBlock>
          {/* <ActionBlock
              to={paths.power_search}
              onClick={() => {
                TrackClick({
                  category: "PowerSearch",
                  action: "PowerSearch - Job Listings - Hero",
                  label: "PowerSearch - Job Listings",
                });
              }}
            >
              <ActionBlockCopy>Discover Hidden Remote Jobs</ActionBlockCopy>
            </ActionBlock> */}
        </ActionBlocks>
        <JobListingsAdMobile />
      </Content>
    </Wrapper>
  );
};
